.psv-tooltip {
    position: absolute;
    z-index: $psv-tooltip-zindex;
    box-sizing: border-box;
    max-width: $psv-tooltip-max-width;
    background: $psv-tooltip-background;
    border-radius: $psv-tooltip-radius;
    padding: $psv-tooltip-padding;
    opacity: 0;
    transition-property: opacity, transform;
    transition-timing-function: ease-in-out;
    transition-duration: $psv-tooltip-animate-delay;

    &-content {
        color: $psv-tooltip-text-color;
        font: $psv-tooltip-font;
        text-shadow: $psv-tooltip-text-shadow;
    }

    &-arrow {
        position: absolute;
        height: 0;
        width: 0;
        border: $psv-tooltip-arrow-size solid transparent;
    }

    &--top-left,
    &--top-center,
    &--top-right {
        transform: translate3d(0, $psv-tooltip-animate-offset, 0);

        .psv-tooltip-arrow {
            border-top-color: $psv-tooltip-arrow-color;
        }
    }

    &--bottom-left,
    &--bottom-center,
    &--bottom-right {
        transform: translate3d(0, -$psv-tooltip-animate-offset, 0);

        .psv-tooltip-arrow {
            border-bottom-color: $psv-tooltip-arrow-color;
        }
    }

    &--left-top,
    &--center-left,
    &--left-bottom {
        transform: translate3d($psv-tooltip-animate-offset, 0, 0);

        .psv-tooltip-arrow {
            border-left-color: $psv-tooltip-arrow-color;
        }
    }

    &--right-top,
    &--center-right,
    &--right-bottom {
        transform: translate3d(-$psv-tooltip-animate-offset, 0, 0);

        .psv-tooltip-arrow {
            border-right-color: $psv-tooltip-arrow-color;
        }
    }

    &--left-top,
    &--top-left {
        box-shadow: #{-$psv-tooltip-shadow-offset} #{-$psv-tooltip-shadow-offset} 0 $psv-tooltip-shadow-color;
    }

    &--top-center {
        box-shadow: 0 #{-$psv-tooltip-shadow-offset} 0 $psv-tooltip-shadow-color;
    }

    &--right-top,
    &--top-right {
        box-shadow: $psv-tooltip-shadow-offset #{-$psv-tooltip-shadow-offset} 0 $psv-tooltip-shadow-color;
    }

    &--left-bottom,
    &--bottom-left {
        box-shadow: #{-$psv-tooltip-shadow-offset} $psv-tooltip-shadow-offset 0 $psv-tooltip-shadow-color;
    }

    &--bottom-center {
        box-shadow: 0 $psv-tooltip-shadow-offset 0 $psv-tooltip-shadow-color;
    }

    &--right-bottom,
    &--bottom-right {
        box-shadow: $psv-tooltip-shadow-offset $psv-tooltip-shadow-offset 0 $psv-tooltip-shadow-color;
    }

    &--center-left {
        box-shadow: #{-$psv-tooltip-shadow-offset} 0 0 $psv-tooltip-shadow-color;
    }

    &--center-right {
        box-shadow: $psv-tooltip-shadow-offset 0 0 $psv-tooltip-shadow-color;
    }

    &--visible {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition-duration: $psv-tooltip-animate-delay;
    }
}
