.psv-container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    background: $psv-main-background;
    overflow: hidden;

    * {
        box-sizing: content-box;
    }
}

.psv-canvas-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $psv-canvas-zindex;
    transition: opacity linear 100ms;
}

.psv-canvas {
    display: block;
}
