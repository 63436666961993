.psv-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $psv-loader-zindex;
}

.psv-loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $psv-loader-color;
    width: $psv-loader-width;
    height: $psv-loader-width;
    outline: $psv-loader-border solid transparent;

    &-canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: $psv-loader-bg-color;
        outline: $psv-loader-tickness solid transparent;
        z-index: -1;
    }

    &-text {
        font: $psv-loader-font;
    }
}
